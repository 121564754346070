interface IPersonalInfo {
  fio: string,
  address: string,
  phone: string,
}

interface ICredit {
  value: number,
  validUntil: string,
  allowed: boolean,
}

interface IInternet {
  tariff: string,
  canBeDisabled: boolean,
  isDisabled: boolean,
}

interface IUser {
  id: number,
  login: string,
  deposit: number,
  monthFee: number,
  personalInfo: IPersonalInfo,
  credit: ICredit,
  internet: IInternet,
}

interface ISchedule {
  id: number,
  scheduleDate: string,
  message: string,
  isCabinetRequest: boolean,
}

export default class User implements IUser {
  constructor(
    public id: number,
    public login: string,
    public deposit: number,
    public monthFee: number,
    public smsService: boolean,
    public hasMegogoService: boolean,
    public personalInfo: IPersonalInfo,
    public credit: ICredit,
    public internet: IInternet,
    public schedules: Array<ISchedule>,
  ) {
  }

  static fromJson = (json: any): User => {
    const personalInfo: IPersonalInfo = json['personal_info'];
    const credit: ICredit = {
      value: json['credit']['value'],
      validUntil: json['credit']['valid_until'],
      allowed: json['credit']['allowed'],
    }
    const internet: IInternet = {
      tariff: json['internet']['tariff'],
      canBeDisabled: json['internet']['can_be_disabled'],
      isDisabled: json['internet']['is_disabled'],
    }
    const schedules = json['schedules'].map((s: any): ISchedule => {
      return {
        id: s['id'],
        scheduleDate: s['schedule_date'],
        message: s['message'],
        isCabinetRequest: s['is_cabinet_request'],
      }
    })
    return new User(
      json['id'],
      json['login'],
      json['deposit'],
      json['month_fee'],
      json['sms_service'],
      json['has_megogo_service'],
      personalInfo,
      credit,
      internet,
      schedules,
    )
  }
}

export type {
  IUser,
  IPersonalInfo,
  ICredit,
  IInternet,
  ISchedule,
}