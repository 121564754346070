import React, {lazy, Suspense} from "react";

import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';

import SiteFooter from './Components/SiteFooter';

import './App.css';
import APIService from "./Services/API";

const AuthPage = lazy(() => import("./Pages/Auth"));
const IndexPage = lazy(() => import("./Pages/Index"));
const InternetPage = lazy(() => import("./Pages/Internet"));
const FinanceActionsPage = lazy(() => import("./Pages/FinanceActions"));
const ChangePasswordPage = lazy(() => import("./Pages/ChangePassword"));
const SettingsPage = lazy(() => import("./Pages/Settings"));

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === '/login/') return;
    !APIService.isAuthenticated && navigate('/login/', {replace: true});
  }, [location.pathname]);

  return (
    <>
      <main>
        <Suspense fallback={<div/>}>
          <Routes>
            <Route path='/' element={<IndexPage/>}>
              <Route path='' element={<InternetPage/>}/>
              <Route path='index' element={<InternetPage/>}/>
              <Route path='personal_info' element={<InternetPage/>}/>
              <Route path='/finance_log' element={<FinanceActionsPage/>}/>
              <Route path='/change_password' element={<ChangePasswordPage/>}/>
              <Route path='/settings' element={<SettingsPage/>}/>
            </Route>
            <Route path='/login/' element={<AuthPage/>}/>
          </Routes>
        </Suspense>
      </main>
      <SiteFooter/>
    </>
  );
}

export default App;
