import User from '../Models/user';
import IPaymentSystem from '../Models/payment_system';
import MegogoService from '../Models/megogo';
import IFinanceAction from '../Models/finance_action';
import IInternetSettings from '../Models/internet_settings';


class APIServiceError extends Error {}

export default class APIService {
  static apiUrl = 'https://control.dim.city/api/v1';
  static authUrl = 'https://control.dim.city/api/token-auth/';

  static async authenticate(username: string, password: string) {
    const init: RequestInit = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username, password}),
    }

    let response: Response;
    try {
      response = await fetch(this.authUrl, init);
    } catch (e) {
      throw new APIServiceError('Невірний логін чи пароль.');
    }
    if (response.status === 200) {
      const responseJson = await response.json();
      APIService.authToken = responseJson['token'];
    } else {
      throw new APIServiceError('Невірний логін чи пароль.');
    }
  }

  static get isAuthenticated(): boolean {
    return this.authToken !== null;
  }

  async getUser(): Promise<User> {
    const response = await this.get(`${APIService.apiUrl}/user/`);
    if (response.status === 200) {
      return User.fromJson(await response.json());
    }
    throw new APIServiceError('Сталася помилка під час обробки запиту.');
  }

  async getCreditService(): Promise<void> {
    const url = `${APIService.apiUrl}/user/credit/`;
    const response = await this.post(url);
    if (response.status !== 200) {
      const responseJson = await response.json();
      throw new APIServiceError(responseJson['error'] || 'Помилка.');
    }
  }

  async getPaymentSystems(): Promise<IPaymentSystem[]> {
    const response = await this.get(`${APIService.apiUrl}/user/payment_systems/`);
    if (response.status === 200) {
      return await response.json();
    }
    return [];
  }

  async scheduleDeactivateInternetService(scheduleDate: string): Promise<void> {
    const data = {'action': 'deactivate', 'date': scheduleDate};
    return await this.scheduleInternetService(data);
  }

  async scheduleActivateInternetService(scheduleDate: string): Promise<void> {
    const data = {'action': 'activate', 'date': scheduleDate};
    return await this.scheduleInternetService(data);
  }

  async scheduleInternetService(data: object): Promise<void> {
    const response = await this.post(`${APIService.apiUrl}/user/schedule/internet/`, data);
    if (response.status !== 200) {
      const responseJson = await response.json();
      throw new APIServiceError(responseJson['error']);
    }
  }

  async deleteSchedule(scheduleId: number): Promise<void> {
    const data = {'schedule_id': scheduleId};
    const response = await this.delete(`${APIService.apiUrl}/user/schedule/internet/`, data);
    if (response.status !== 204) {
      const responseJson = await response.json();
      throw new APIServiceError(responseJson['error']);
    }
  }

  async connectSMSInformService(phoneNumber: string): Promise<void> {
    const data = {phone: phoneNumber};
    const response = await this.post(`${APIService.apiUrl}/user/sms/`, data);
    if (response.status !== 200) {
      const responseJson = await response.json();
      throw new APIServiceError(responseJson['error']);
    }
  }

  async validateSMSInformServiceCode(phoneNumber: string, code: string): Promise<void> {
    const data = {phone: phoneNumber, code: code};
    const response = await this.put(`${APIService.apiUrl}/user/sms/`, data);
    if (response.status !== 200) {
      const responseJson = await response.json();
      throw new APIServiceError(responseJson['error']);
    }
  }

  async getMegogoServiceDetails(): Promise<MegogoService | null> {
    const response = await this.get(`${APIService.apiUrl}/user/megogo/`);
    if (response.status === 200) {
      return await response.json();
    }
    return null;
  }

  async getFinanceActions(page: number): Promise<IFinanceAction[]> {
    const url = `${APIService.apiUrl}/user/finance_actions/?page=${page}`;
    const response = await this.get(url);
    const responseJson = await response.json();
    if (response.status === 200) return responseJson;
    throw new APIServiceError(responseJson['error']);
  }

  async changePassword(oldPassword: string, newPassword: string, newPasswordRepeat: string): Promise<null> {
    const data = {old_password: oldPassword, new_password: newPassword, new_password_repeat: newPasswordRepeat};
    const response = await this.post(`${APIService.apiUrl}/user/change_password/`, data);
    if (response.status === 200) return null;
    const responseJson = await response.json();
    throw new APIServiceError(responseJson['error']);
  }

  async getInternetSettings(): Promise<IInternetSettings> {
      const response = await this.get(`${APIService.apiUrl}/user/ip_address/`);
    if (response.status === 200) return await response.json();
    throw new APIServiceError('Невідома помилка.');
  }

  private get = async (url: string): Promise<Response> => {
    return await this.makeResponse(url, 'GET');
  }

  private post = async (url: string, body?: object): Promise<Response> => {
    return await this.makeResponse(url, 'POST', body);
  }

  private put = async (url: string, body?: object): Promise<Response> => {
    return await this.makeResponse(url, 'PUT', body);
  }

  private delete = async (url: string, body?: object): Promise<Response> => {
    return await this.makeResponse(url, 'DELETE', body);
  }

  private makeResponse = async (url: string, method: string, body?: object): Promise<Response> => {
    const init: RequestInit = {
      method,
      headers: APIService.headers,
      body: body ? JSON.stringify(body) : null,
    }
    try {
      return await fetch(url, init);
    } catch (e) {
      throw new APIServiceError('Сталася помилка під час обробки запиту.');
    }
  }

  private static get authToken(): string | null {
    return localStorage.getItem('cabinet_auth_token');
  }

  private static set authToken(token: string | null) {
    if (token) {
      localStorage.setItem('cabinet_auth_token', token);
    } else {
      localStorage.removeItem('cabinet_auth_token');
    }
  }

  private static get headers(): HeadersInit {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${APIService.authToken}`,
    }
  }
}

export {
  APIServiceError,
}