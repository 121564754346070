import React, {FC, memo} from 'react';

import styles from './styles.module.scss';

const Index: FC = () => {
  return (
    <footer className={styles.site_footer}>
      <div className="container">
        <div className="row">
          <div className={`col-xs-6 ${styles.site_footer__copyright}`}>
            <b>&copy; ТІМ 2023</b>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default memo(Index, () => true);