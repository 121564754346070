import React from 'react';
import ReactDOM from 'react-dom/client';

import {BrowserRouter} from "react-router-dom";
import SnackbarProvider from 'react-simple-snackbar';

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <SnackbarProvider>
      <App/>
    </SnackbarProvider>
  </BrowserRouter>
);

